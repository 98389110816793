/* rails-erb-loader-dependencies ../config/secrets.yml */

import Bugsnag from '@bugsnag/js'

const bugsnagClient = Bugsnag.createClient({
  apiKey: '9e2b4b2336b31a3a7bd4b2d648ace4c6',
  collectUserIp: false,
  releaseStage: "staging",
  enabledReleaseStages: ["production", "staging"]
})

document.addEventListener("turbo:load", function () {
  let $body = $('body');
  let user_id = $body.data('user-id')
  let user_name = $body.data('user-name')
  let user_email = $body.data('user-email')
  let organization_id = $body.data('organization-id')
  let organization_name = $body.data('organization-name')
  let organization_plan = $body.data('organization-plan')
  let organization_plan_interval = $body.data('organization-plan-interval')

  bugsnagClient.user = {id: user_id, name: user_name, email: user_email};
  bugsnagClient.metaData = {organization: {id: organization_id, name: organization_name, plan: organization_plan, plan_interval: organization_plan_interval}};
});
